import React from "react";
import logo from "../assets/useimg/logoblack.png";
import heroimg from "../assets/useimg/heroani.png";

const Header = () => {
  return (
    <>
      <style>
        {`
          #header .logo img {
            max-height: none !important;
            width: 120px !important;
            height: 120px !important;
            object-fit: contain !important;
            object-position: center !important;
          }
        `}
      </style>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <a
              href="/"
              className="logo"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                marginRight: "10px",
              }}
            >
              <img
                src={logo}
                alt="Jing Logo"
                style={{
                  width: "120px",
                  height: "120px",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
              />
            </a>
            <h1
              className="logo-text"
              style={{
                display: "inline-block",
                verticalAlign: "middle",
                margin: 0,
                fontSize: "2.4em",
                fontWeight: "bold",
              }}
            >
              <a href="index.html" style={{ textDecoration: "none" }}>
                <span style={{ color: "#ffffff" }}>Jing</span>
              </a>
            </h1>
          </div>

          <nav id="navbar" className="navbar">
            <div>
              <a
                id="btn-get-black"
                className="btn-get-black scrollto"
                href="https://app.jing.cash/"
                style={{ textDecoration: "none" }}
              >
                Launch App
              </a>
            </div>
          </nav>
        </div>
        <img src={heroimg} className="header-img" alt="Hero" />
      </header>
    </>
  );
};

export default Header;
