import React from "react";
import coinImage1 from "../assets/Neonimg/coins/Group 1000011698-1.svg";
import coinImage2 from "../assets/Neonimg/coins/Group 1000011699.svg";
import coinImage3 from "../assets/Neonimg/coins/Group 1000011700-1.svg";
import coinImage4 from "../assets/Neonimg/coins/Group 1000011703-1.svg";
import coinImage5 from "../assets/Neonimg/coins/Group 1000011704-1.svg";
import coinImage6 from "../assets/Neonimg/coins/Layer_1-1.svg";
import coinImage7 from "../assets/Neonimg/coins/USDA.svg";
import coinImage8 from "../assets/Neonimg/coins/sUSDT.svg";
import coinImage9 from "../assets/Neonimg/coins/xUSD.svg";
import coinImage10 from "../assets/Neonimg/coins/Layer_1.svg";

const Tradecoins = () => {
  return (
    <section id="contact" className="contact section-bg">
      <style jsx>{`
        @keyframes moveCoins {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .coins-container {
          display: flex;
          overflow: hidden;
          width: 100%;
          height: 60px; /* Adjust height as needed */
          position: relative;
        }

        .coins-wrapper {
          display: flex;
          width: 200%;
          animation: moveCoins 30s linear infinite;
        }

        .coin {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin: 0 8px; /* Adjust margin as needed */
        }
        .coins-container::before,
        .coins-container::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50px; /* Adjust width as needed */
          z-index: 1;
          pointer-events: none;
        }

        .coins-container::before {
          left: 0;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0)
          );
        }

        .coins-container::after {
          right: 0;
          background: linear-gradient(
            to left,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0)
          );
        }
      `}</style>
      <div className="container">
        <div className="section-title">
          <br />
          <h2 id="tradeedit">
            Whales
            <span style={{ color: "#AB40FF" }}>
              {" "}
              waste
              <br /> thousands{" "}
            </span>
            of dollars <br />
            using AMMs
          </h2>
          <p id="tradeparr" style={{ color: "#fff" }}>
            Eating at their profits and wasting their time ☹️
          </p>
          <br />
          <br />
          <div className="coins-container">
            <div className="coins-wrapper">
              <img src={coinImage1} alt="coin1" className="coin" />
              <img src={coinImage2} alt="coin2" className="coin" />
              <img src={coinImage3} alt="coin3" className="coin" />
              <img src={coinImage4} alt="coin4" className="coin" />
              <img src={coinImage5} alt="coin5" className="coin" />
              <img src={coinImage6} alt="coin6" className="coin" />
              <img src={coinImage7} alt="coin7" className="coin" />
              <img src={coinImage8} alt="coin8" className="coin" />
              <img src={coinImage9} alt="coin9" className="coin" />
              <img src={coinImage10} alt="coin1" className="coin" />
              <img src={coinImage2} alt="coin2" className="coin" />
              <img src={coinImage3} alt="coin3" className="coin" />
              <img src={coinImage4} alt="coin4" className="coin" />
              <img src={coinImage5} alt="coin5" className="coin" />
              <img src={coinImage6} alt="coin6" className="coin" />
              <img src={coinImage7} alt="coin7" className="coin" />
              <img src={coinImage8} alt="coin8" className="coin" />
              <img src={coinImage9} alt="coin9" className="coin" />
              <img src={coinImage1} alt="coin1" className="coin" />
              <img src={coinImage10} alt="coin2" className="coin" />
              <img src={coinImage3} alt="coin3" className="coin" />
              <img src={coinImage4} alt="coin4" className="coin" />
              <img src={coinImage5} alt="coin5" className="coin" />
              <img src={coinImage6} alt="coin6" className="coin" />
              <img src={coinImage7} alt="coin7" className="coin" />
              <img src={coinImage8} alt="coin8" className="coin" />
              <img src={coinImage9} alt="coin9" className="coin" />
              <img src={coinImage1} alt="coin1" className="coin" />
              <img src={coinImage2} alt="coin2" className="coin" />
              <img src={coinImage10} alt="coin3" className="coin" />
              <img src={coinImage4} alt="coin4" className="coin" />
              <img src={coinImage5} alt="coin5" className="coin" />
              <img src={coinImage6} alt="coin6" className="coin" />
              <img src={coinImage7} alt="coin7" className="coin" />
              <img src={coinImage8} alt="coin8" className="coin" />
              <img src={coinImage9} alt="coin9" className="coin" />
              <img src={coinImage1} alt="coin1" className="coin" />
              <img src={coinImage2} alt="coin2" className="coin" />
              <img src={coinImage3} alt="coin3" className="coin" />
              <img src={coinImage10} alt="coin4" className="coin" />
              <img src={coinImage5} alt="coin5" className="coin" />
              <img src={coinImage6} alt="coin6" className="coin" />
              <img src={coinImage7} alt="coin7" className="coin" />
              <img src={coinImage8} alt="coin8" className="coin" />
              <img src={coinImage9} alt="coin9" className="coin" />
              <img src={coinImage1} alt="coin1" className="coin" />
              <img src={coinImage2} alt="coin2" className="coin" />
              <img src={coinImage3} alt="coin3" className="coin" />
              <img src={coinImage4} alt="coin4" className="coin" />
              <img src={coinImage10} alt="coin5" className="coin" />
              <img src={coinImage6} alt="coin6" className="coin" />
              <img src={coinImage7} alt="coin7" className="coin" />
              <img src={coinImage8} alt="coin8" className="coin" />
              <img src={coinImage9} alt="coin9" className="coin" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tradecoins;
