import React from "react";
import illustration from "../assets/useimg/illustration.svg";
import Ready from "./Ready";
import aboutImage from "../assets/useimg/img-fluide.png";

const Platform = () => {
  return (
    <div
      style={{ background: "transparent" }}
      id="platform"
      className="platform"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-1 d-flex align-items-center">
            <div
              className="img-container"
              style={{ width: "100%", height: "100%" }}
            >
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={aboutImage}
                className="img-fluid"
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content">
            <p id="platp" style={{ color: "#FF7975" }}>
              Jing connects whales through a secure and efficient peer-to-peer
              network, removing the need for intermediaries and eliminating
              their associated fees.{" "}
            </p>

            <br />
            <p id="platp" style={{ color: "#FFFFFF" }}>
              Our platform guarantees that every swap is executed at the
              agreed-upon rate, ensuring no hidden costs or value loss.
            </p>
            <br />
            <div className="text-center">
              <a
                href="https://docs.jing.cash/"
                className="btn-get-black text-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read More <i className="bi bi-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Ready />
    </div>
  );
};

export default Platform;
