import React from "react";
import illustration from "../assets/useimg/illustration.svg";
import img1 from "../assets/useimg/img-01.png";
import img2 from "../assets/useimg/img-02.png";
import img3 from "../assets/useimg/img-03.png";

const Works = () => {
  return (
    <section id="services" className="services section-bg">
      <div className="container">
        <div className="section-title">
          <h2 id="tradeedit" style={{ color: "#ffffff" }}>
            Discover safe P2P
          </h2>
        </div>
        <div className="row gy-4">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon">
                <img id="servive" src={img1} alt="Lorem Ipsum" />
              </div>
              <h4>01</h4>
              <p id="wordspara">Select a market</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon">
                <img id="servive" src={img2} alt="Sed Perspiciatis" />
              </div>
              <h4>02</h4>
              <p id="wordspara">Create a limit order</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon text-right">
                <img id="servive" src={img3} alt="Magni Dolores" />
              </div>
              <h4>03</h4>
              <p id="wordspara">Swap without slippage, that simple.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
