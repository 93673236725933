import React from "react";
import aboutImage from "../assets/useimg/change.png";

const Intro = () => {
  return (
    <section id="about" className="about">
      <style jsx>{`
        .img-container {
          position: relative;
          display: inline-block;
          overflow: hidden;
        }

        .img-container img {
          display: block;
          max-width: 100%;
          height: auto;
        }

        .wave {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          height: 80%;
          border-radius: 50%;
          background: radial-gradient(
            circle closest-side,
            rgba(255, 102, 52, 0.1),
            rgba(255, 102, 52, 0.5)
          );
          animation: waveAnimation 2s linear infinite;
          pointer-events: none;
          z-index: 1;
        }

        @keyframes waveAnimation {
          0% {
            transform: translate(-50%, -50%) scale(0);
            opacity: 0.5;
          }
          50% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 0.2;
          }
          100% {
            transform: translate(-50%, -50%) scale(0);
            opacity: 0;
          }
        }
      `}</style>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-2 order-lg-2">
            <div className="img-container">
              <img
                style={{ width: "100%", height: "100%" }}
                src={aboutImage}
                className="img-fluid"
                alt="About"
              />
              <div className="wave"></div>
            </div>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 content">
            <h3 id="infohead" style={{ color: "#FF7975" }}>
              <span id="tradeedit">Enter</span>
            </h3>
            <h3 id="infohead" style={{ color: "#FFFFFF" }}>
              <span id="tradeedit">Jing</span>
            </h3>
            <p id="infopow" style={{ color: "#ab40ff" }}>
              Secure, guaranteed prices. Every time.
            </p>
            <br />
            <a
              href="https://docs.jing.cash/"
              className="btn-get-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read More <i className="bi bi-long-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
