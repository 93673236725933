import React from "react";
import cancelimg from "../assets/useimg/amms.png";
import checkImage from "../assets/useimg/ams.png";

const Whybeef = () => {
  return (
    <section id="whybeef" className="whybeef">
      <div className="container">
        <div className="section-title">
          <h2 id="tradeedit">
            <span>Why</span> P2P?
          </h2>
        </div>
        <div id="whyrow" className="row justify-content-center">
          <div
            id="whyfirst"
            className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 content"
          >
            <div className="inner-content">
              <h3 id="tradeedit">AMMs</h3>
              <ul>
                <li>
                  <img src={cancelimg} alt="Check" className="check-icon" />{" "}
                  <span id="bettern">Tx failures</span>
                </li>
                <li>
                  <img src={cancelimg} alt="Check" className="check-icon" />{" "}
                  <span id="bettern">Death candles</span>
                </li>
                <li>
                  <img src={cancelimg} alt="Check" className="check-icon" />{" "}
                  <span id="bettern">Cascade liquidations</span>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="whysecod"
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-2 content"
          >
            <div className="inner-content">
              <h3 id="tradeedit">Swap with Jing</h3>
              <ul>
                <li style={{ color: "#0C0C0D" }}>
                  <img src={checkImage} alt="Check" className="check-icon" />{" "}
                  <span id="bettern">Price guaranteed</span>
                </li>
                <li style={{ color: "#0C0C0D" }}>
                  <img src={checkImage} alt="Check" className="check-icon" />
                  <span id="bettern">No chart impact</span>
                </li>
                <li style={{ color: "#0C0C0D" }}>
                  <img src={checkImage} alt="Check" className="check-icon" />{" "}
                  <span id="bettern">Secure and Exclusive P2P</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whybeef;
