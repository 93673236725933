import React from "react";
import heroimg from "../assets/useimg/heroani.png";

const Hero = () => {
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-12 col-lg-12 pt-3 pt-lg-0 order-1 order-lg-1 d-flex flex-column">
            <div id="herotext">
              <h1>
                Safe P2P Swaps
                <br /> Without Slippage
              </h1>
              <h2>Jing gets you secure, guaranteed prices. Every time.</h2>
              <div>
                <a
                  href="https://app.jing.cash/"
                  className="btn-get-started scrollto"
                >
                  Try Now
                </a>
              </div>
            </div>
          </div>
          {/* 
          <div className="col-xl-4 col-lg-6 order-2 order-lg-2 hero-img animated">
            <img src={heroimg} className="img-fluid" alt="" />
          </div> 
          */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
