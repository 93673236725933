import React, { useState, useEffect } from "react";
import aboutImage from "../assets/useimg/Subtract-img.png";
import image from "../assets/useimg/intro-bg.png";
import image1 from "../assets/useimg/img-01.png";
import image2 from "../assets/useimg/Ellipse.svg";
import Works from "./Works";
import Clients from "./Clients";
import "./users.css";

const users = [
  {
    id: 1,
    name: "@BigApe_1",
    img: image,
    testimonial:
      "Jing has transformed how I trade. No more slippage means more profits",
  },
  {
    id: 2,
    name: "BigApe_1",
    img: image1,
    testimonial:
      "Jing has transformed how I trade. No more slippage means more profits",
  },
  {
    id: 3,
    name: "@BigApe_1",
    img: image2,
    testimonial:
      "Jing has transformed how I trade. No more slippage means more profits",
  },
  // Add more users as needed
];

const Testimony = () => {
  const [currentUser, setCurrentUser] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUser((prevUser) => (prevUser + 1) % users.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section id="testcap" className="testcap">
      <section id="testimony" className="testimony">
        <Clients />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="img-container position-relative">
                <img id="testimg" src={aboutImage} className="img-fluid" alt="About" />
                <div
                  style={{ color: "#ff7975" }}
                  className="button-top-left position-absolute"
                >
                  Hear from our users
                </div>
                <div className="testimonials-center position-absolute">
                  <p>"{users[currentUser].testimonial}"</p>
                </div>
                <div className="user-images-bottom-right position-absolute">
                  <div className="user-images">
                    {users.map((user, index) => (
                      <img
                        key={user.id}
                        src={user.img}
                        alt={user.name}
                        className={`user-img ${
                          currentUser === index ? "active" : ""
                        }`}
                      />
                    ))}
                  </div>
                  <div className="username">{users[currentUser].name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <Works />
      </section>
    </section>
  );
};

export default Testimony;
