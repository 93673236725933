import React from "react";

const Ready = () => {
  return (
    <section id="readybg" className="readybg">
      <div className="container">
        <div className="section-title">
          <br />
          <br />
          <br />
          <br />
          <h2 id="tradeedit" style={{ color: "#fff" }}>
            Ready to swap without <br /> compromise?
          </h2>
        </div>
        <div className="text-center">
          <a href="https://app.jing.cash/" className="btn-get-black scrollto">
            Try Now
          </a>
        </div>
        <br />
      </div>
    </section>
  );
};

export default Ready;
