import React from "react";
import Header from "./components/Header";
import Hero from "./components/Heros";
import Tradecoin from "./components/Tradecoins";
import Intro from "./components/Intro";
import Testimony from "./components/Testimony";
import Ready from "./components/Ready";
import Footer from "./components/Footer";
import Whybeef from "./components/Whybeef";
import Platform from "./components/Platform";

import "./assets/vendor/aos/aos.css";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/css/style.css";
// ju

function App() {
  return (
    <>
      <body>
        <Header />
        <main id="main">
          <Hero />
          <Tradecoin />
          <Whybeef />
          <Intro />
          <Testimony />
          <Platform />
        </main>
        <Footer />
      </body>
    </>
  );
}

export default App;
