import React from "react";
import logo from "../assets/useimg/logoblack.png";

const Footer = () => {
  return (
    <>
      <style>
        {`
          #footer .logo img {
            width: 80px !important;
            height: 80px !important;
            max-height: none !important;
            object-fit: contain !important;
            object-position: center !important;
          }
        `}
      </style>
      <footer id="footer">
        <div className="container">
          <div className="copyright-wrap d-md-flex py-4">
            <div className="me-md-auto text-center text-md-start">
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="/"
                  className="logo"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "15px",
                  }}
                >
                  <img
                    src={logo}
                    alt="Jing Logo"
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                </a>
                <h1
                  className="logo-text"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    margin: 0,
                    fontSize: "2.5em",
                    fontWeight: "bold",
                  }}
                >
                  <a href="index.html" style={{ textDecoration: "none" }}>
                    <span style={{ color: "#ffffff" }}>Jing</span>
                  </a>
                </h1>
              </div>
              <br />
              <br />

              <div style={{ color: "white" }} className="copyright">
                Copyright &copy;
                <strong>
                  <span> 2024 </span>
                </strong>
                Uasu Finance
              </div>
            </div>
            <div className="social-links text-center text-md-right pt-3 pt-md-0">
              <a
                href="https://x.com/JingCash/status/1806072500020760799"
                className="twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-twitter-x"></i>
              </a>
              <a
                href="https://t.me/JingCash"
                className="telegram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-telegram"></i>
              </a>
              <a
                href="https://discord.gg/UEkHNVqgjF"
                className="discord"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-discord"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
