import React from "react";
import part1 from "../assets/useimg/btc.png";
import part2 from "../assets/useimg/mor.png";
import part3 from "../assets/useimg/fon.png";
import part4 from "../assets/useimg/cit.png";

const Clients = () => {
  return (
    <div id="clients" className="clients section">
      <div className="container">
        <div className="row gy-4">
          <div className="col-xl-3 col-md-3 col-6 client-logo">
            <img
              src={part1}
              className="img-fluid"
              alt="Client 1"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div className="col-xl-3 col-md-3 col-6 client-logo">
            <img
              src={part2}
              className="img-fluid"
              alt="Client 2"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div className="col-xl-3 col-md-3 col-6 client-logo">
            <img
              src={part3}
              className="img-fluid"
              alt="Client 3"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div className="col-xl-3 col-md-3 col-6 client-logo">
            <img
              src={part4}
              className="img-fluid"
              alt="Client 4"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
